import { useEffect } from 'react';
import { useRouter } from 'next/router';

import IsLoading from 'components/is-loading';

function Index() {
  const router = useRouter();

  useEffect(() => {
    router.replace(
      { pathname: '/app/quests' },
      undefined,
      { shallow: true },
    );
  }, [router]);

  return (
    <IsLoading />
  );
}

export default Index;
